import * as React from "react"
import {buildSeo} from "../utils/seo";

// markup
const Legales = () => {
  //               <p>Je suis disponible pour du développement web en freelance, que ce soit du front ou du back !</p>

  return (
      <>
        {buildSeo({
          title: 'Moi, Loris',
          description: "Développeur full-stack vivant à Annecy, Loris est diplomé de l'école des Gobelins en Design et Management de l'innovation intéractive."
        })}
        <div className={"page-content about"}>
          <section className={"container"}>
            <div className={"background-title-container background-title-container__left as-title"}>
              <h1 className={"background-title"}>Mentions légales</h1>
            </div>
            <article>
                <h2>Propriété</h2>
              <p>
                  Ce portfolio est la propriété de PINNA.DEV, une auto-entreprise gérée par Loris Pinna.
              </p>
                <p>
                    <strong>SIRET : </strong> 82842252700022
                </p><br/>
                <h2>Crédits & propriété</h2>
                <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.</p>
                <p>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle</p>
                <p>Images et textes : Loris Pinna</p>
                <p>Photos :
                    <a href="https://unsplash.com/fr/@sigmund?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Sigmund</a>, <a href="https://unsplash.com/fr/@justin_morgan?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Justin Morgan</a>, <a href="https://unsplash.com/fr/@servuspaul?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Paul Felberbauer</a>, <a href="https://unsplash.com/fr/@craftedbygc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unseen Studio</a> &  <a href="https://unsplash.com/fr/@clemhlrdt?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Clément Hélardot</a>
                </p>
                <h2>Données personnelles</h2>
                <p>Car nous n'avons pas besoin de tirer des statistiques des visiteurs de ce site, seul les données relatives aux demandes de contact sont enregistrées. Il n'existe qu'une copie de ces données, dans la boite mail liée à ce site. Aucune sauvegarde n'est effectuée en base de données.</p>
                <p>Vous pouvez à tout moment vous rétracter et nous demander la suppression de vos données en contactant le responsable du site.</p>
            </article>
          </section>
        </div>
      </>
  )
}

export default Legales
